import { Component } from '@angular/core';
import { ToastService } from './core/services/info/toast-service.service';
import { environment } from 'src/environments/environment';

/**
 * The AppComponent is used to bootstrap (start) the Angular application. It is activated by the <app-root>-tag in the
 * index.html, which matches the selector of this component and thus triggers the display of the AppComponent template,
 * containing the header, <router-outlet>, and footer components.
 */
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  /**
   * Used to inject the toast service into the entire application.
   */
  constructor(
    public toastService: ToastService,
    ){
      console.log('Backend URL: ' + environment.backendURL);
      console.log('Keycloak URL: ' + environment.keycloakURL);
      console.log('Grafana URL: ' + environment.grafanaURL);
  }

  title = 'frontend';
}
