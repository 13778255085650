<app-dialog
  *ngIf="showAlert | async"
  (onClose)="hideAlert()"
>
  <div class="message-wrapper">
    Are you sure to {{(actionType | async) !== TreeItemTypes.ASSET ? "delete" : "archive"}} {{elementForDeleting?.name}}?
    {{(actionType | async) === TreeItemTypes.ASSET ? "You will no longer be able to unarchive it." : ""}}
  </div>
  <div class="button-wrapper">
    <p-button
      class="alert-button"
      styleClass="p-button-outlined p-button-secondary"
      (onClick)="hideAlert()"
    >
      Cancel
    </p-button>
    <p-button
      class="alert-button"
      styleClass="p-button-outlined p-button-secondary"
      (onClick)="confirmHandler()"
    >
      Confirm
    </p-button>
  </div>
</app-dialog>
