import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { GetDataService } from 'src/app/core/services/data/get-data.service';
import { ScheduleDataService } from 'src/app/core/services/data/schedule-data.service';
import { ToastService } from 'src/app/core/services/info/toast-service.service';
import {MODEL_EDITOR_ROUTE, TILE_MANAGEMENT_ROUTE} from "../../../../projects/pr/src/app/shared/constants/routes";
import {KeycloakEventType, KeycloakService } from 'keycloak-angular';
import { HttpClient } from '@angular/common/http';

/**
 * This component is responsible for displaying the application's header and avtivating the correct routes.
 *
 * @author Sebastian Wenzel
 */
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  protected readonly TILE_MANAGEMENT_ROUTE = TILE_MANAGEMENT_ROUTE;
  protected readonly MODEL_EDITOR_ROUTE = MODEL_EDITOR_ROUTE;
  protected amphinicyBlinkAvailable: boolean;
  constructor(
    /** Service needed here for Navigation */
    public dataService: GetDataService,
    /** Service needed here for Navigation */
    public scheduleDataService: ScheduleDataService,
    /** Service needed here for enable or disable the Toast-Feedback */
    public toastService: ToastService,
    private keycloak: KeycloakService,
    private http: HttpClient,

  ) {
  }

  /**
   * @ignore
   */
  ngOnInit(): void {
    
    this.checkamphinicyBlinkAvailable();  

    // this.keycloak.keycloakEvents$.subscribe(event => {
    //     if (event.type == KeycloakEventType.OnTokenExpired) {
    //       this.keycloak.updateToken(20);
    //     }
    //   }
    // );
  }

  /**
   * @return A boolean telling the application whether the navigation links should be shown (only when logged in).
   */
  showTopNav(): boolean {
    return true;
    // return this.authService.loggedIn;
  }

  isRealmAdmin() : boolean{
    let realm_mgmt = this.keycloak.getKeycloakInstance().resourceAccess?.["realm-management"];

    if (realm_mgmt && realm_mgmt.roles) {
      return realm_mgmt.roles.includes('realm-admin');
    } else {
      return false;
    }
  }

  /**
   * Retrieves the current user's username from the browser's session storage.
   */
  showUserName(): string {
    return sessionStorage.getItem(environment.userName)!;
  }
  logout():void {
    sessionStorage.removeItem(environment.keyAccessToken);
    sessionStorage.removeItem(environment.userName);
    sessionStorage.removeItem(environment.scheduleUUIDName);

    this.keycloak.logout(window.location.origin).then((value) => {
      this.toastService.showSuccess('Logout successful', 'Redirecting to login page');
    }); 
  }

  getUserSettingsLink(): string {
    /*
    example: https://localhost:8443/realms/myrealm/account?referrer=frontend&referrer_uri=https%3A%2F%2Flocalhost%3A4200%2Fmanual
    */

    return this.keycloak.getKeycloakInstance().createAccountUrl();
  }

  getUserAdminLink(): string {
    let realm = this.keycloak.getKeycloakInstance().realm;
    return this.keycloak.getKeycloakInstance().authServerUrl + "/admin/" + realm + "/console";
  }

  checkamphinicyBlinkAvailable() {
    if(environment.blinkURL === '') {
      this.amphinicyBlinkAvailable = false;
      return
    }
    this.amphinicyBlinkAvailable = true;

    // this.http
    // .get(this.getAmphinicyBlinkURL(), {responseType: 'text', observe: 'response' })
    // .subscribe({
    //   next: (n) => {        
    //       if(n.status==200 || n.status == 301){
    //           this.amphinicyBlinkAvailable = true;
    //       } else {
    //           this.amphinicyBlinkAvailable = false;
    //       }
    //     },
    //   error: (error) => {
    //     //console.log('error', error);
    //     this.amphinicyBlinkAvailable = true;
    //   }
    // }
    // );
  }

  getAmphinicyBlinkURL() {
    return environment.blinkURL;
  }
}
