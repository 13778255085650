export class Asset {
      name: string;
      roles: string[];
      asset_uuid: string;
      

      constructor(name: string, roles: string[], asset_uuid: string) {
        this.name = name;
        this.roles = roles;
        this.asset_uuid = asset_uuid;
      }

      static fromJSON(jsonSystem:any) {
    
        return new Asset(jsonSystem.name, jsonSystem.roles, jsonSystem.asset_uuid);
      }

      toJson() {
        return JSON.stringify(this);
      }

      saveInSessionStorage() {
        sessionStorage.setItem(this.asset_uuid, this.toJson())
      }

      loadFromSessionStorage() {
        return Asset.fromJSON(sessionStorage.getItem(this.asset_uuid));
      }

      public static extractRelevantRoles(assetName: string, roles: string[]): string[] {
        let relevantRoles: string[] = [];
        roles.forEach(roleCandidate => {
          if (roleCandidate.startsWith(assetName)) {
            relevantRoles.push(roleCandidate);
          }
        });
        return relevantRoles;
      }

      static extractFromToken(assetName: string, tokenParsed: any): Asset | undefined {
        let foundAsset = undefined;
        Object.keys(tokenParsed!.assets).forEach((name: any) => {
          if (name === assetName) {
            let assetUUID = tokenParsed!.assets[name]['schedule_uuid'];
            let roles = this.extractRelevantRoles(name, tokenParsed.groups);
            foundAsset = Asset.fromJSON({name: assetName, roles: roles, asset_uuid:assetUUID});
          }
        });
        return foundAsset
      }

}

// export class SystemData {
//   number: number;
//   name: string;

//   constructor(number: number, name:string) {
//     this.name = name;
//   }

//   static fromJSON(json: any): SystemData{
//     let number = Object.keys(json)[0];
//     let name = json[number];
//     return new SystemData(Number(number), name);
//   }

// }


    
  
  