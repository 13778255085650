import { Injectable } from '@angular/core';
import { FEActivity } from '../../../modules/shared/scheduleElements/activity.model';

/**
 * This service contains the logic for detecting and filtering manual commands or activities that contain manual commands.
 *
 * @author Daniel Koch
 */
@Injectable({
  providedIn: 'root',
})
export class ManualFilterService {
  constructor() {}

  /**
   * Checks if an Activity contains one or more manual commands. Since a manual command always creates an empty Activity,
   * it is sufficient to check first whether even one command is included in a sequence of the Activity.
   * @param activity To Check if is an empty Activity with manual command
   * @returns True if manual Activity
   */
  checkIfManual(activity: FEActivity): boolean {

    return !activity.activity.autoScheduled;
    // let isManual = false;
    // if (activity.sequences.length === 1) {
    //   Array.from(activity.sequences).forEach((seq) => {
    //     if (seq.commands.length === 1) {
    //       seq.commands.forEach((com) => {
    //         if (com.manual) {
    //           isManual = true;
    //         } else {
    //           isManual = false;
    //         }
    //       });
    //     }
    //   });
    // }
    // return isManual;
  }

  /**
   * Checks for each object from the input array to see if it is a manual command,
   * and then returns the manual commands.
   * @param scheduleSet Array for checking
   * @returns Array of manual commands
   */
  getManuals(scheduleSet: any[]): any[] {
    const manualArr: any[] = [];
    scheduleSet.forEach((o) => {
      if (o.manual) {
        manualArr.push(o);
      }
    });
    return manualArr;
  }
}
