// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
declare var require: any;

export const environment = {
  production: true,
  backendURL: getBackendURL(),
  websocket: getBackendURL(),

  keycloakURL: getKeycloakdURL(),
  keycloakClientId: 'frontend',
  keycloakRealm: getRealmName(),

  grafanaURL: getGrafanaURL(),

  blinkURL : getBlinkURL(),

  keyAccessToken: 'access-token',
  userName: 'userName',
  assetName: 'assetName',
  scheduleUUIDName: 'scheduleUUID',
  roleName:'GUEST',
  VERSION: require('../../package.json')['version']
};

function checkIpAddress(ip: string) { 
  /*  Check if an ip is in the url 

      No characters before or after the ip are allowed:
      
      255.255.255.255 returns True
      https://255.255.255.255 returns False
      255.255.255.255:80 returns False
  
  */
    const ipv4Pattern =  
        /^([0-9]{1,3}\.){3}[0-9]{1,3}$/; 
    const ipv6Pattern =  
        /^([0-9a-fA-F]{1,4}:){7}[0-9a-fA-F]{1,4}$/; 
    return ipv4Pattern.test(ip) || ipv6Pattern.test(ip); 
}

function getBackendURL() {
    if(checkIpAddress(getRootURL())){
        return "https://" + getRootURL() + ':8081';
    }else{
        return 'https://api.' + getHostEnv() + '.satio.space';
    }
}

function getKeycloakdURL() {
    if(checkIpAddress(getRootURL())){
        return "https://" + getRootURL() + ':8443';
    }else{
        return 'https://keycloak.' + getHostEnv() + '.satio.space';
    }
}

function getBlinkURL(){
  if(checkIpAddress(getRootURL())){
    return "";
  } else {
  return 'https://amph.' + getHostEnv() + '.satio.space';
  }
}


function getGrafanaURL() {
  if(checkIpAddress(getRootURL())){
      return "https://" + getRootURL() + ':3000';
    }else{
      return 'https://grafana.' + getHostEnv() + '.satio.space';
  }
}

function getRealmName() {
  if(checkIpAddress(getRootURL())){
    return 'test';
}else{
    return getHostEnv();
}
}

function getRootURL() {
    /*
  Extracts the root url. E.g. for a url of 'https://console.test.satio.space' it returns 'console.test.satio.space'
  For 'https://localhost:4200' it returns 'localhost'
  */
  return window.location.href.split('/')[2].split(':')[0];
}

function getHostEnv() {
  /*
  Extracts the host environment. E.g. for a url of 'test.satio.space' it returns 'test'
  */

  let rootUrl = getRootURL().split('.')[0];
  if (rootUrl === undefined) {
    throw new Error('Cannot extract host environment. Got ' + getRootURL() + ' as URL');
  }else {
    return rootUrl;
  }
}
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.


