import {Component, Input, OnInit} from '@angular/core';

/**
 * This component represents a button which can be disabled and display a loading indicator based on given boolean
 * conditions.
 */
@Component({
  selector: 'app-loading-button',
  templateUrl: './loading-button.component.html',
  styleUrls: ['./loading-button.component.scss']
})
export class LoadingButtonComponent implements OnInit {
  /**
   * The text to display inside the button.
   */
  @Input() message?: string;
  @Input() cls?: string;
  
  
  /**
   * When should the button be disabled?
   */
  @Input() disabled?: boolean;
  /**
   * When should the loading indicator be shown?
   */
  @Input() showSpinnerIf?: boolean;

  constructor() {}

  ngOnInit(): void {
  }
}
