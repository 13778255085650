import { Component, OnInit } from '@angular/core';
import {NotificationService} from "../../services/notification.service";
import {Observable} from "rxjs";
import {notificationAnimation} from "../../constants/animations";

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.sass'],
  animations: [notificationAnimation]
})
export class NotificationComponent {
  constructor(private notificationService: NotificationService) { }

  public get head(): Observable<string | null> {
    return this.notificationService.head$
  }

  public get message(): Observable<string | null> {
    return this.notificationService.text$
  }

  public get showNotification(): Observable<boolean> {
    return this.notificationService.showNotification$
  }

  public hideHandler(): void {
    this.notificationService.hideNotification()
  }
}
