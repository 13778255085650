import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  private _showLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  public readonly $showLoading: Observable<boolean> = this._showLoading.asObservable()

  public showLoading(): void {
    this._showLoading.next(true)
  }

  public hideLoading(): void {
    this._showLoading.next(false)
  }
}
