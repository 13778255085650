import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {DeleteElementService} from "../../../services/delete-element.service";
import {Observable} from "rxjs";
import {Node} from "../../../models/node";
import {TreeItemTypes} from "../../../enum/tree-item-types";

@Component({
  selector: 'app-cancel-alert',
  templateUrl: './cancel-alert.component.html',
  styleUrls: ['./cancel-alert.component.sass']
})
export class CancelAlertComponent  implements OnInit {
  public elementForDeleting: Node | null
  protected readonly TreeItemTypes = TreeItemTypes;
  @Output() public readonly onConfirm: EventEmitter<void> = new EventEmitter<void>()
  @Output() public readonly onClose: EventEmitter<void> = new EventEmitter<void>()
  constructor(private alertService: DeleteElementService) {}

  public ngOnInit(): void {
    this.alertService.elementForDeleting$.subscribe(element => {
      this.elementForDeleting = element as Node
    })
  }

  public get showAlert(): Observable<boolean> {
    return this.alertService.showAlert$
  }

  public get actionType(): Observable<string | null> {
    return this.alertService.actionType$
  }

  public confirmHandler(): void {
    this.alertService.confirm()
  }

  public hideAlert(): void {
    this.alertService.hideAlert()
  }
}
