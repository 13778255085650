import {animate, state, style, transition, trigger} from "@angular/animations";

export const toggleSidebarAnimation = trigger('toggleSidebarAnimation', [
  transition(':enter', [
    style({ display: "none" }),
    animate('0.2s ease-out', style({ display: "block" })),
  ]),
]);

export const dialogAnimation = trigger('openClose', [
  state('open', style({
    opacity: 1,
    visibility: "visible"
  })),
  state('closed', style({
    opacity: 0,
    visibility: "hidden"
  })),
  transition('open => closed', [
    animate('0.3s')
  ]),
  transition('closed => open', [
    animate('0.3s')
  ])
])

export const notificationAnimation = trigger('notificationAnimation', [
  transition(':enter', [
    style({ top: -200 }),
    animate('0.5s ease-out',
      style({ top: 10 })
    ),
  ]),
  transition(
    ':leave', [
      style({ top: 10 }),
      animate('0.5s ease-in',
        style({ top: -200 })
      )
    ]
  )
]);
