<div class="context-menu" [ngStyle]="{'left.px': x, 'top.px': y}">
  <div class="card bg-white shadow-lg">
    <div class="card-body p-3">
      <div class="container">
        <div class="row mb-2">
          <button (click)="edit()" class="btn btn-primary">
            Edit
          </button>
        </div>
        <div class="row mb-2">
          <button (click)="manual()" class="btn btn-primary">
            Set Manual
          </button>
        </div>
        <div class="row">
          <button (click)="delete()" class="btn btn-danger">
            Delete
          </button>
        </div>
      </div>
    </div>
  </div>
</div>