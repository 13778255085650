import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { FEFullCommand } from '../../scheduleElements/fullcommand.model';

@Component({
  selector: 'app-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss']
})
export class ContextMenuComponent implements OnInit {

  @Input() x=0;
  @Input() y=0;

  @Input() clickedCmd: FEFullCommand | undefined;

  @Output() deleteCmd = new EventEmitter<FEFullCommand>();
  @Output() editCmd = new EventEmitter<FEFullCommand>();
  @Output() manualCmd = new EventEmitter<FEFullCommand>();
  
  @Output() clickedOutside = new EventEmitter();


  constructor(private eRef: ElementRef) { }

  ngOnInit(): void {
  }

  @HostListener("document:click", ["$event"])
  clickout(event: MouseEvent) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.clickedOutside.emit();
    }
  }

  delete() {
    this.deleteCmd.emit(this.clickedCmd);
  } 

  edit() {
    this.editCmd.emit(this.clickedCmd);
  }

  manual() {
    this.manualCmd.emit(this.clickedCmd);
  }

}
