import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

/**
 * This component contains the template of the confirm modal
 *
 * @author Daniel Koch
 */
@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss'],
})
export class ConfirmModalComponent implements OnInit {
  /** Properties to set by opening a confirm modal */
  header = '';
  whatToDo = '';
  name = '';
  undoAvail = false;

  constructor(public modal: NgbActiveModal) {}

  /**
   * @ignore
   */
  ngOnInit(): void {}
}
