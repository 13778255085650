<ngb-toast
  *ngFor="let toast of toastService.toasts"
  [class]="toast.classname"
  [autohide]="true"
  [delay]="toast.delay || 3000"
  (hidden)="toastService.remove(toast)"
  header="{{ toast.header }}"
>
  <ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
    <ng-template [ngTemplateOutlet]="toast.text"></ng-template>
  </ng-template>

  <ng-template #text>{{ toast.text }}</ng-template>
</ngb-toast>