import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private _showNotification: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  private _isError: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  private _head: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null)
  private _text: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null)

  public readonly showNotification$: Observable<boolean> = this._showNotification.asObservable()
  public readonly head$: Observable<string | null> = this._head.asObservable()
  public readonly text$: Observable<string | null> = this._text.asObservable()

  public toggleNotification(head: string, text: string = "", isError: boolean = false): void {
    this._head.next(head)
    this._isError.next(isError)
    this._showNotification.next(true)
    if (text) {
      this._text.next(text)
    }

    setTimeout(() => this.hideNotification(), 5000)
  }

  public hideNotification(): void {
    this._showNotification.next(false)
    this._text.next(null)
    this._head.next(null)
  }
}
