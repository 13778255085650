<div class="row mb-3 bg-primary mx-3">
  <nav class="navbar sticky-top navbar-expand-lg navbar-dark">
    <div class="container-fluid">
      <a class="navbar-brand text-lg-start" routerLink="/systems">
        <img
          src="assets/img/satio-logo-white.png"
          height="50em"
          class="d-inline-block align-top"
          alt="Logo"
        />
      </a>

      <div
        class="collapse navbar-collapse"
        id="navbarSupportedContent"
        *ngIf="showTopNav()">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item">
            <a
              class="nav-link"
              routerLink="manual"
              routerLinkActive="active"
              >SAT.command</a>
          </li>
          <!-- <li *ngIf="isAdmin()" class="nav-item">
            <a
              class="nav-link"
              routerLink="user"
              routerLinkActive="active"
              >User Permission</a> -->
          <!-- <li class="nav-item">
            <a
              class="nav-link"
              routerLink="mcs"
              routerLinkActive="active"
              >MCS Status</a>
          </li> -->
          
          <li class="nav-item">
            <a
              class="nav-link"
              [routerLink]="'pr/' + MODEL_EDITOR_ROUTE"
              routerLinkActive="active"
              >SAT.edit</a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              routerLink="/grafana"
              routerLinkActive="active"
              >SAT.view</a>
          </li>

          <li class="nav-item">
            <a
              class="nav-link"
              routerLink="planning"
              routerLinkActive="active"
              >SAT.plan</a>

          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              routerLink="world"
              routerLinkActive="active"
              >World view</a>
          </li>
          <li *ngIf="amphinicyBlinkAvailable" class="nav-item">
            <a
              class="nav-link"
              href="{{ getAmphinicyBlinkURL() }}"
              routerLinkActive="active"
              >Amphinicy Blink</a>
          </li>
        </ul>
      </div>
        <div ngbDropdown class="dropdown">
          <button ngbDropdownToggle class="btn btn-secondary bg-transparent text-light border-0" type="button" id="dropdownMenuButton1" aria-expanded="false">
            User: {{ showUserName() }}
          </button>
          <div ngbDropdownMenu aria-labelledby="dropdownMenuButton1">
            <button 
              ngbDropdownItem 
              class="btn"
              (click)="logout()"
              routerLinkActive="active">
              Log out
            </button>
            <button 
              ngbDropdownItem 
              class="btn"
              routerLinkActive="active">
              <a href="{{ getUserSettingsLink() }}">
                Profile Settings
              </a>
            </button>
            <button
              *ngIf="isRealmAdmin()"
              ngbDropdownItem
              class="btn"> 
              <a              
              href="{{ getUserAdminLink() }}"
              target="_blank" 
              rel="noopener noreferrer"
              routerLinkActive="active">
              Admin User Settings
            </a>
            </button>
          </div>
        </div>
    </div>
  </nav>
</div>
