<div class="vh-100 content mx-3 overflow-auto scroller d-flex flex-column">
    <app-header></app-header>
    <div class="mx-3 flex-grow-1">
        <router-outlet></router-outlet>
    </div>
    <app-footer></app-footer>
  <app-toasts-container *ngIf="toastService.showFeedback" aria-live="polite" aria-atomic="true"></app-toasts-container>
</div>


