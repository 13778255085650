import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { LoadingButtonComponent } from './loading-button/loading-button.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { ContextMenuComponent } from './subcomponents/context-menu/context-menu.component';

/**
 * This module should contain all components, pipes and directives which can be used across the entire application.
 *
 * @author Sebastian Wenzel
 */
@NgModule({
  imports: [
    CommonModule,
    FormsModule,

    ReactiveFormsModule,
    NgbModule,
    RouterModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    LoadingButtonComponent,
    NgbModule,
    RouterModule,
    ContextMenuComponent
  ],
  declarations: [
    LoadingButtonComponent,
    ContextMenuComponent,
  ],
})
export class SharedModule { }
