import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from './modules/page-not-found/page-not-found.component';
import { AuthGuard } from './core/auth.guard';
/**
 * These are all routes used by the Angular application. Use the 'canActivate' property to have the route protected
 * by the AuthGuard.
 */
const routes: Routes = [
  // {
  //   path: 'auth',
  //   loadChildren: () =>
  //     import('./modules/auth/auth.module').then((m) => m.AuthModule),
  // },
  {
    path: 'settings',
    loadChildren: () =>
      import('./modules/settings/settings.module').then(
        (m) => m.SettingsModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'planning',
    loadChildren: () =>
      import('./modules/mission-planning/mission-planning.module').then(
        (m) => m.MissionPlanningModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'manual',
    loadChildren: () =>
      import('./modules/manual/manual.module').then(
        (m) => m.ManualModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'grafana',
    loadChildren: () =>
      import('./modules/grafana/grafana.module').then(
        (m) => m.GrafanaModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'mcs',
    loadChildren: () =>
      import('./modules/mcs/mcs.module').then(
        (m) => m.McsModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'systems',
    loadChildren: () =>
      import('./modules/systems/systems.module').then(
        (m) => m.SystemsModule
      ),
      canActivate: [AuthGuard],
  },
  {
    path: 'world',
    loadChildren: () =>
      import('./modules/cesium/cesium.module').then(
        (m) => m.CesiumModule
      ),
      canActivate: [AuthGuard],
  },
  { path: '404', component: PageNotFoundComponent },
  { path: '', redirectTo: 'manual', pathMatch: 'full' }, // Starting point for the application
  { path: '**', redirectTo: '404', pathMatch: 'full' }, // 404 page
];

/**
 * The AppRoutingModule is responsible for binding components to routes used by the browser.
 *
 */
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
