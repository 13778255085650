import {Component, EventEmitter, Input, Output} from '@angular/core';
import {dialogAnimation} from "../../../constants/animations";

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.sass']
})
export class DialogComponent {
  @Output() public readonly onClose = new EventEmitter<void>()

  public closeHandler(): void {
    this.onClose.emit()
  }
}
