import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutModule} from './layout/layout.module';
import { PageNotFoundComponent } from './modules/page-not-found/page-not-found.component';
import { HttpClientModule} from '@angular/common/http';
import { httpInterceptorProvider} from './interceptors';
import { CoreModule} from './core/core.module';
import { NgbModule, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfirmModalComponent } from './modules/modals/confirm-modal/confirm-modal.component';
import { ToastsContainerComponent } from './core/components/toasts-container/toasts-container.component';
import { CommonModule } from '@angular/common';
import { CommandConfModule } from '../../projects/pr/src/app/app.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { initializeKeycloak } from './init/keycloak-init.factory';
import { SystemsModule } from './modules/systems/systems.module';


/**
 * This is the top-level module of an Angular application.
 */
@NgModule({
  declarations: [AppComponent, PageNotFoundComponent, ConfirmModalComponent, ToastsContainerComponent],
  imports: [
    CommandConfModule.forRoot(),
    CommonModule,
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    LayoutModule,
    CoreModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    KeycloakAngularModule,
    SystemsModule,
  ],
  providers: [httpInterceptorProvider, NgbActiveModal,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService],
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
