import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SystemOverviewComponent } from './system-overview/system-overview.component';


const routes: Routes = [
  { path: '', component: SystemOverviewComponent }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ManualRoutingModule {}


@NgModule({
  declarations: [],
  imports: [
    RouterModule.forChild(routes)   
  ],
  exports: [RouterModule],
})
export class SystemsRoutingModule { }
