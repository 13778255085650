import {EventEmitter, Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {DeletingEmit} from "../models/deleting-emit";
import {ElementForDeleting} from "../models/element-for-deleting";

@Injectable({
  providedIn: 'root'
})
export class DeleteElementService {
  private readonly _showAlert: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  private readonly _actionType: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null)
  private readonly _elementForDeleting: BehaviorSubject<ElementForDeleting | null> = new BehaviorSubject<ElementForDeleting | null>(null)
  public readonly showAlert$: Observable<boolean> = this._showAlert.asObservable();
  public readonly elementForDeleting$: Observable<ElementForDeleting | null> = this._elementForDeleting.asObservable();
  public readonly onConfirm: EventEmitter<DeletingEmit> = new EventEmitter<DeletingEmit>()
  public readonly actionType$: Observable<string | null> = this._actionType.asObservable()

  public toggleAlert(node: ElementForDeleting, actionType: string): void {
    this._elementForDeleting.next(node)
    this._actionType.next(actionType)
    this._showAlert.next(true)
  }

  public hideAlert(): void {
    this._showAlert.next(false)
    this._elementForDeleting.next(null)
  }

  public confirm(): void {
    this.onConfirm.emit({data: this._elementForDeleting.value!, actionType: this._actionType.value})
    this.hideAlert()
  }
}
