import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LayoutComponent} from '../layout/layout.component';
import {MODEL_EDITOR_ROUTE, TILE_MANAGEMENT_ROUTE} from '../shared/constants/routes';


const routes: Routes = [{
  path: 'pr',
  component: LayoutComponent,
  children: [
    {path: '', redirectTo: MODEL_EDITOR_ROUTE, pathMatch: 'full'},
    {path: MODEL_EDITOR_ROUTE, loadChildren: () => import('./model-editor/model-editor.module')
        .then(m => m.ModelEditorModule)
    },
    {path: TILE_MANAGEMENT_ROUTE, loadChildren: () => import('./tile-management/tile-management.module')
        .then(m => m.TileManagementModule)
    }
  ]
}];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppPrRoutingModule { }
