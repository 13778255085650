import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Node, Version} from "../../../models/node";

@Component({
  selector: 'app-release-dialog',
  templateUrl: './release-dialog.component.html',
  styleUrls: ['./release-dialog.component.sass']
})
export class ReleaseDialogComponent implements OnInit {
  public minor: number | null = null
  public major: number | null = null
  public patch: number | null = null
  public buttonsDisabled: boolean = false
  public showAlert: boolean = false
  @Input() public asset: Node | null = null
  @Output() public readonly onClose = new EventEmitter<void>()
  @Output() public readonly onConfirm = new EventEmitter<Version>()

  public ngOnInit(): void {
    this.resetVersion()
  }

  public confirmHandler(): void {
    this.onConfirm.emit({
      major: this.major,
      minor: this.minor,
      patch: this.patch
    })
  }

  public closeHandler(): void {
    this.onClose.emit()
    this.resetVersion()
  }

  public majorHandler(): void {
    this.minor = 0
    this.patch = 0
    this.major! += 1
    this.buttonsDisabled = true

  }

  public minorHandler(): void {
    this.patch = 0
    this.minor! += 1
    this.buttonsDisabled = true
  }

  public patchHandler(): void {
    this.patch! += 1
    this.buttonsDisabled = true
  }

  public resetVersion(): void {
    this.major = this.asset?.version?.major ?? 0
    this.minor = this.asset?.version?.minor ?? 0
    this.patch = this.asset?.version?.patch ?? 0
    this.buttonsDisabled = false
  }
}
