import {NgModule} from "@angular/core";
import {DialogComponent} from "./dialogs/dialog/dialog.component";
import {ButtonModule} from "primeng/button";
import {ReleaseDialogComponent} from "./dialogs/release-dialog/release-dialog.component";
import {CancelAlertComponent} from "./dialogs/cancel-alert/cancel-alert.component";
import {CommonModule} from "@angular/common";
import { LoadingComponent } from './loading/loading.component';
import {ProgressSpinnerModule} from "primeng/progressspinner";
import { NotificationComponent } from './notification/notification.component';

const commonDeclarations = [
  DialogComponent,
  ReleaseDialogComponent,
  CancelAlertComponent
];

@NgModule({
  imports: [
      CommonModule,
      ButtonModule,
      ProgressSpinnerModule
  ],
  declarations: [...commonDeclarations, LoadingComponent, NotificationComponent],
  providers: [],
  exports: [...commonDeclarations, LoadingComponent, NotificationComponent],
})
export class SharedComponentsModule {}
