<div *ngIf="assets.length > 0; then withAssets; else noAssets"></div>

<ng-template #withAssets>
  <h2>Your assets</h2>
  <div class="row justify-content-start">
  <div *ngFor="let asset of assets" class="col-md-4 col-lg-2">
    <span (click)="assetSelection(asset)" class="nav-link" routerLinkActive="active">
      <asset-tile [asset]="asset"></asset-tile>
    </span>
  </div>
</div>
</ng-template>

<ng-template #noAssets>
  <h2>No mission(s) or assets available</h2>
  Either you are missing the correct rights or no assets exist. Use the Model Editor to create assets.
</ng-template>
