import {ModuleWithProviders, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import {SharedModule} from "./shared/shared.module";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {HttpClientModule} from "@angular/common/http";
import {FormsModule} from "@angular/forms";
import {LayoutModule} from './layout/layout.module';
import {AppPrRoutingModule} from './routes/routes.module';

const providers: never[] = [];
@NgModule({
  declarations: [
    AppComponent
  ],
    imports: [
        BrowserModule,
        AppPrRoutingModule,
        SharedModule.forRoot(),
        BrowserAnimationsModule,
        HttpClientModule,
        FormsModule,
        LayoutModule,
    ],
  providers: providers,
  bootstrap: [AppComponent]
})
export class AppModule { }

@NgModule({})
export class CommandConfModule {
  static forRoot(): ModuleWithProviders<NgModule> {
    return {
      ngModule: AppModule,
      providers: providers
    }
  }
}
