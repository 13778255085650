<app-dialog
  (onClose)="closeHandler()"
>
  <h3 class="dialog-head">Please specify the release version:</h3>
  <div class="version-wrapper">
    <label class="version-type">Major number</label>
    <div class="version-number">{{major}}</div>
    <p-button
      (onClick)="majorHandler()"
      [disabled]="buttonsDisabled"
      icon="pi pi-plus"
      class="increase-version-button"
      styleClass="p-button-rounded p-button-secondary p-button-text p-button-sm">
    </p-button>
  </div>
  <div class="version-wrapper">
    <label class="version-type">Minor number</label>
    <div class="version-number">{{minor}}</div>
    <p-button
      (onClick)="minorHandler()"
      [disabled]="buttonsDisabled"
      icon="pi pi-plus"
      class="increase-version-button"
      styleClass="p-button-rounded p-button-secondary p-button-text p-button-sm">
    </p-button>
  </div>
  <div class="version-wrapper">
    <label class="version-type">Patch number</label>
    <div class="version-number">{{patch}}</div>
    <p-button
      (onClick)="patchHandler()"
      [disabled]="buttonsDisabled"
      icon="pi pi-plus"
      class="increase-version-button"
      styleClass="p-button-rounded p-button-secondary p-button-text p-button-sm">
    </p-button>
  </div>
  <div class="buttons-wrapper">
    <p-button
      class="release-dialog-button"
      styleClass="p-button-outlined p-button-secondary"
      (onClick)="closeHandler()"
    >
      Cancel
    </p-button>
    <p-button
      class="release-dialog-button"
      styleClass="p-button-outlined p-button-secondary"
      (onClick)="resetVersion()"
    >
      Reset numbers
    </p-button>
    <p-button
      class="release-dialog-button"
      styleClass="p-button-outlined p-button-secondary"
      (onClick)="confirmHandler()"
    >
      Confirm
    </p-button>
  </div>
  <app-cancel-alert
    *ngIf="showAlert"
  >
    Are you sure you want to close the page? <br>
    The unreleased changes will be lost.
  </app-cancel-alert>
</app-dialog>
