import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from "@angular/common";
import {ButtonModule} from "primeng/button";
import {InputTextModule} from "primeng/inputtext";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {DropdownModule} from "primeng/dropdown";
import { TooltipModule } from 'primeng/tooltip';
import {InputTextareaModule} from "primeng/inputtextarea";
import {RouterModule} from '@angular/router';
import {SharedComponentsModule} from "./components/shared-components.module";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ButtonModule,
    InputTextModule,
    ReactiveFormsModule,
    DropdownModule,
    FormsModule,
    TooltipModule,
    InputTextareaModule,
    SharedComponentsModule
  ],
  declarations: [
  ],
  providers: [],
  exports: [
    CommonModule,
    RouterModule,
    ButtonModule,
    InputTextModule,
    ReactiveFormsModule,
    DropdownModule,
    FormsModule,
    TooltipModule,
    InputTextareaModule
  ],
})
export class SharedModule {
  public static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule
    }
  }
}
