import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SystemOverviewComponent } from './system-overview/system-overview.component';
import { SystemsRoutingModule } from './systems-routing.module';
import { AssetTileComponent } from './system-overview/asset-tile/asset-tile.component';



@NgModule({
  declarations: [
    SystemOverviewComponent,
    AssetTileComponent
  ],
  imports: [
    CommonModule,
    SystemsRoutingModule
  ]
})
export class SystemsModule { }
