import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
/**
 * A regular old footer, can be used to display miscellaneous information such as license, admin contact, and much more.
 */
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})

export class FooterComponent implements OnInit {

  constructor() { }


  ngOnInit(): void {
  }

  getVersionInformation() : string {
    return environment.VERSION;
  }

}
