import { HttpParams } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { GetDataService } from 'src/app/core/services/data/get-data.service';
import { Asset } from 'src/app/modules/shared/missionModel/system-interface';

@Component({
  selector: 'asset-tile',
  templateUrl: './asset-tile.component.html',
  styleUrls: ['./asset-tile.component.scss']
})
export class AssetTileComponent implements OnInit {

  @Input() asset:Asset;

  latestVersion: string = '-.-.-'

  constructor(    
    private dataInterface: GetDataService
  ) { }

  ngOnInit(): void {
  }

  getNotifications() {
    return 10;
  }

}
