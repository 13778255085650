import { Component, HostBinding, TemplateRef } from '@angular/core';
import { ToastService } from 'src/app/core/services/info/toast-service.service';

/**
 * Container for all toast feedbacks. This container contains the template for a toast, which is called by the ToastService.
 * In this container default duration and default styling of toasts can be customized.
 *
 * @author Daniel Koch
 */
@Component({
  selector: 'app-toasts-container',
  templateUrl: './toasts-container.component.html',
  styleUrls: ['./toasts-container.component.scss']
})
export class ToastsContainerComponent {
  constructor(public toastService: ToastService) {}

  @HostBinding('class.ngb-toasts')
  hasBaseClass = true;

 /**
  * Simple method for NgbBootstrap toasts to determine if it is text or template for toast.
  * @param toast either as text or template
  * @returns true if template
  */
   isTemplate(toast: { textOrTpl: any }): boolean {
    return toast.textOrTpl instanceof TemplateRef;
  }
}
