import { Injectable } from '@angular/core';
import { FEActivity } from 'src/app/modules/shared/scheduleElements/activity.model';

/**
 * This service is used to calculate the earliest start and the latest end of the execution time of an activity.
 * This is especially important for the Gantt view and the Table view in the ScheduleView,
 * so that the user can see when the first and when the last command of an activity is executed.
 *
 * @author Daniel Koch
 */
@Injectable({
  providedIn: 'root',
})
export class DurationCalcService {

  constructor() {}

  /**
   * Calculates the maximum execution time of an activity,
   * taking the maximum execution time from all commands for each sequence of an activity.
   * @param activity Activity for which the calculation is to be performed
   * @returns The maximum execution time of the activity
   */
  public static calcMaxExecutionTime(activities: FEActivity[]): Date {
    let max_dates: Date[] = [];
    activities.forEach(activity => {
      let dates = DurationCalcService.getDates(activity);
      max_dates.push(DurationCalcService.maxDate(dates));
    });    
    return DurationCalcService.maxDate(max_dates);
  }

  public static maxDate = (dates: Date[]) => new Date(Math.max(...dates.map(Number)));
  public static minDate = (dates: Date[]) => new Date(Math.min(...dates.map(Number)));

  private static getDates(act: FEActivity): Date[] {
    //get all dates
    let dates: Date[] = []
    act.getCommands().forEach(cmd => {
      if(cmd.absoluteExecutionTime !== undefined) {
        dates.push(cmd.absoluteExecutionTime);
      }
    });
    return dates;
  }

  /**
   * Calculates the minimum execution time of an activity,
   * taking the minimum execution time from all commands for each sequence of an activity.
   * @param activity Activity for which the calculation is to be performed
   * @returns The minimum execution time of the activity
   */
  public static calcMinExecutionTime(activities: FEActivity[]): Date {
    let min_dates: Date[] = [];
    activities.forEach(activity => {
      let dates = DurationCalcService.getDates(activity);
      min_dates.push(DurationCalcService.minDate(dates));
    });
    return DurationCalcService.minDate(min_dates);
  }
}
