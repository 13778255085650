import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { LayoutComponent } from './layout.component';
import {GridsterModule} from "angular-gridster2";
import {NgForOf} from "@angular/common";
import {SharedComponentsModule} from "../shared/components/shared-components.module";

@NgModule({
    imports: [
        SharedModule,
        GridsterModule,
        NgForOf,
        SharedComponentsModule,
    ],
    providers: [],
    declarations: [
      LayoutComponent
    ],
    exports: []
})
export class LayoutModule { }
