import { Component } from '@angular/core';
import {LoadingService} from "../../services/loading.service";
import {Observable} from "rxjs";
@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.sass']
})
export class LoadingComponent {
  constructor(private loadingService: LoadingService) { }

  public get showLoading$(): Observable<boolean> {
    return this.loadingService.$showLoading
  }
}
