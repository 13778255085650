import { Injectable, TemplateRef } from '@angular/core';

/**
 * This service is intended to enable the fast and uncomplicated display of different types of toast.
 * It does not matter from where the service is called, because a toast can always be displayed as an overlay over the entire application.
 *
 * @author Daniel Koch
 */
@Injectable({ providedIn: 'root' })
export class ToastService {
  toasts: any[] = [];
  showFeedback = true;

  /**
   * Shows toast. Can be modified by text or template and different styling options.
   * @param textOrTpl Text or template of the toast
   * @param [options] Styling options of the toast
   */
  show(header: string, text: string | TemplateRef<any>, options: any = {}): void {
    this.toasts.push({ header, text, ...options });
  }

  /**
   * Removes toast from view
   * @param toast Toast to remove from view
   */
  remove(toast: any): void {
    this.toasts = this.toasts.filter((t) => t !== toast);
  }

  /**
   * Shows toast with success background color for successful operations.
   * @param count Number of successful operations
   * @param name Name of Object if count is 1
   * @param operation Name of the operation which is perfomed
   * @param objects Name of Objects if count is bigger than 1
   */
  showSuccess(
    header: string,
    text: string,
  ): void {
    this.show(header, text, {classname: 'bg-success text-light'});

    // if (count > 0) {
    //   if (count === 1 && count > 0) {
    //     this.show(operation + ': ' + name + '.', {
    //       classname: 'bg-success text-light',
    //     });
    //   } else {
    //     this.show(operation + ': ' + count + ' ' + objects + '.', {
    //       classname: 'bg-success text-light',
    //     });
    //   }
    // }
  }

  /**
   * Shows toast with danger background color for very critical operations.
   * @param count Number of very critical operations
   * @param name Name of Object if count is 1
   * @param operation Name of the operation which is perfomed
   * @param objects Name of Objects if count is bigger than 1
   */
  showDanger(
    header: string,
    text: string,
  ): void {
    this.show(header, text, {classname: 'bg-danger text-dark', delay: 4000});
    // if (count > 0) {
    //   if (count === 1) {
    //     this.show(operation + ': ' + name + '.', {
    //       classname: 'bg-danger text-light',
    //       delay: 4000,
    //     });
    //   } else {
    //     this.show(operation + ': ' + count + ' ' + objects + '.', {
    //       classname: 'bg-danger text-light',
    //       delay: 4000,
    //     });
    //   }
    // }
  }

  /**
   * Shows toast with danger background color for critical operations.
   * @param count Number of critical operations
   * @param name Name of Object if count is 1
   * @param operation Name of the operation which is perfomed
   * @param objects Name of Objects if count is bigger than 1
   */
  showWarning(
    header: string,
    text: string,
  ): void {
    this.show(header, text, {classname: 'bg-warning text-dark', delay: 4000});
    // if (count > 0) {
    //   if (count === 1) {
    //     this.show(operation + ': ' + name + '.', {
    //       classname: 'bg-warning',
    //       delay: 4000,
    //     });
    //   } else {
    //     this.show(operation + ': ' + count + ' ' + objects + '.', {
    //       classname: 'bg-warning',
    //       delay: 4000,
    //     });
    //   }
    // }
  }
}
