<div
  *ngIf="showNotification | async"
  @notificationAnimation
  class="notification-wrapper"
  (click)="hideHandler()"
>
  <div class="notification">
    <h3 class="notification__head">
      {{head | async}}
    </h3>
    <p class="notification__text">
      {{message | async}}
    </p>
    <p-button
      icon="pi pi-times"
      styleClass="p-button-rounded p-button-secondary p-button-text close-button"
      (click)="hideHandler()"
    ></p-button>
  </div>
</div>
