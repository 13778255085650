import { Injectable } from '@angular/core';


/**
 * This singleton service holds the current Schedule ID and UUID for the whole application.
 *
 *  */
@Injectable({
  providedIn: 'root',
})
export class ScheduleDataService {
  /** Stores the current schedule UUID */
  scheduleUuid!: string | undefined;

  constructor() {
  }
}
