import {Injectable} from '@angular/core';
import {HttpInterceptor, HttpEvent, HttpRequest, HttpHandler} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import { Route, Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';


/**
 * This interceptor automatically attaches authentication information to requests and then passes the request on to
 * the next interceptor in the chain.
 */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  /**
   * The constructor of the AuthInterceptor registers the services used in the intercept()-method.
   */
  constructor(
    private router: Router,
    protected readonly keycloak: KeycloakService) {
  }
  /**
   * Attaches the accessToken to every request, using the HTTP 'Authorization' header with the 'Bearer' scheme.
   *
   * @param httpRequest The request tobe modified
   * @param next the next handler in the chain.
   */
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    let url = `${environment.backendURL}/${request.url}`

    if (request.url.startsWith('http')) {
      url = `${request.url}`
    }

    let httpReq = request.clone({ url });

    // this.keycloak.isLoggedIn().then((loggedIn:boolean) => {
    if(this.keycloak.isLoggedIn()) {
      this.keycloak.getToken().then((token: string) => {
        httpReq = request.clone({
          setHeaders: {Authorization: `Bearer ${token}`},
          url
        });          
      })
    } else {
      this.keycloak.login(
        {
          redirectUri: window.location.href,
        }
      ); 
    }
    // });
    return next.handle(httpReq);
  }  
}
