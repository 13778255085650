import { Component, OnInit } from '@angular/core';
import { Asset } from '../../shared/missionModel/system-interface';
import { KeycloakService } from 'keycloak-angular';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'system-overview',
  templateUrl: './system-overview.component.html',
  styleUrls: ['./system-overview.component.scss']
})

export class SystemOverviewComponent implements OnInit {

  assets: Asset[]=[];
  missionName = "unknown";

  constructor(
    private keycloak: KeycloakService,
    ) {
  }

  ngOnInit(): void {

    let tokenParsed = this.keycloak.getKeycloakInstance().tokenParsed;

    sessionStorage.setItem(environment.userName, tokenParsed!.preferred_username);

    if (tokenParsed?.assets) {
      Object.keys(tokenParsed!.assets).forEach((name: any) => {
        this.assets.push(Asset.extractFromToken(name, tokenParsed)!);
      });
    }
    this.assets = this.assets.sort((a, b) => a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase()));

  }

  assetSelection(system:Asset){
    sessionStorage.setItem(environment.scheduleUUIDName, system.asset_uuid);
    sessionStorage.setItem(environment.assetName, system.name);
  }


}
