import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { FEActivity } from '../../../modules/shared/scheduleElements/activity.model';
import { DurationCalcService } from '../data-handling/duration-calc.service';
import { ManualFilterService } from '../data-handling/manual-filter.service';
import { ScheduleDataService } from './schedule-data.service';
import { FESchedule } from 'src/app/modules/shared/scheduleElements/schedule.model';
import { FECommand } from 'src/app/modules/shared/scheduleElements/command.model';

/**
 * This singleton service is for all data exchange from frontend to the backend REST API with json data.
 * This means that all GET, PUT, POST and DELETE operations are executed via this service and sent to the REST API.
 * In addition, the path names for the interface for schedules, activities, sequences and commands are listed here.
 *
 */

@Injectable({
  providedIn: 'root',
})
export class GetDataService {

  /** Schedule Elements */
  activities: FEActivity[] = [];
  schedule!: FESchedule;
  loaded = false;

  /** REST-Paths */
  readonly activitiesPath = 'activities';
  readonly schedulesPath = 'schedules';
  readonly commandsPath = 'commands';


  constructor(
    private http: HttpClient,
    private manualFilterService: ManualFilterService,
    public scheduleDataService: ScheduleDataService,
  ) {
  }

  /**
   * Gets the current schedule with the help of the schedule ID.
   * 'GET' Operation.
   * @returns A schedule object that contains multiple activities.
   */


  /**
   * Gets REST data in any form. 'GET' Operation.
   * @param url Resource URL of the requested REST data
   * @returns Resource data as array
   */
  getRestData(url: string, params?: HttpParams): Observable<any[]> {
    return this.http.get<any[]>(url, {params:params});
  }

  /**
   * Gets commands from dummy data set.
   * @returns Commands as array
   */
  getCommands(): Observable<FECommand[]> {
    return this.http.get<FECommand[]>('/assets/command-data.json');
  }

  /**
   * Gets activities of stored actvitiy array object.
   * @returns Activities as array
   */
  getActivities(): FEActivity[] {
    return this.activities;
  }

  /**
   * Calcs begin and end execution for each activity of an array.
   * @param activities Array of activities
   */
  calcBeginAndEndExecution(activities: FEActivity[]): void {
    activities.forEach((ac) => {
      ac.executionStart = DurationCalcService.calcMinExecutionTime([ac]);
      ac.executionEnd = DurationCalcService.calcMaxExecutionTime([ac]);
    });
  }

  /**
   * Filters out of an array of activities those that contain a manual command.
   * @param activities to filter
   * @returns Activities, which do not contain manual commands.
   */
  filterManuals(activities: FEActivity[]): FEActivity[] {
    let filtered!: FEActivity[];
    filtered = activities.filter(
      (ac) => !this.manualFilterService.checkIfManual(ac)
    );
    return filtered;
  }

  /**
   * Request for 'DELETE' of resource objects to the REST API.
   * @param url URL of the resources
   * @param objects Array of objects to delete
   */
  deleteRestData(url: string, object: any): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      params: new HttpParams().append('uuid', object),
    };
    return this.http.delete(url, options);
  }

  deleteRestDataBody(url: string, object: any | any[]): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: object,
    };
    return this.http.delete(url, options);
  }

  /**
   * Request for 'PUT' of resource objects to the REST API.
   * @param url URL of the resources
   * @param objects Array of objects to put
   */
  putRestData(url: string, objects: any[]): Observable<any> {
    return this.http.put<any>(url, objects);
  }

  /**
   * Request for 'POST' of resource objects to the REST API.
   * @param url URL of the resources
   * @param objects Array of objects to post
   */
  postRestData(url: string, objects: any[]): void {
    this.http
      .post<any>(url, objects)
      .subscribe((s) => {

      });
  }

  /**
   * Request for 'POST' of resource objects to the REST API and returns a Observable
   * @param url URL of the resources
   * @param objects Array of objects to post
   * @returns Observable
   */
    postRestDataObservable(url: string, objects: any[]): Observable<any> {
      return this.http.post<any>(url, objects)
    }

  getSimpleScheduleData(scheduleUuid: string | undefined): Observable<any[]> {
    if (scheduleUuid !== undefined) {
      return this.getRestData('schedules/simple', new HttpParams().append('uuid', scheduleUuid));
    } else {
      return this.getRestData('schedules/simple');
    }
  }
}
