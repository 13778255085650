<div
  class="dialog-container"
>
  <div
    class="dialog"
  >
    <ng-content></ng-content>
    <p-button
      icon="pi pi-times"
      styleClass="p-button-rounded p-button-secondary p-button-text close-button"
      (click)="closeHandler()"
    ></p-button>
  </div>
</div>
