<div class="wrapper sat-io" id="sat-io">
<!--  <app-sidebar class="aside-container"></app-sidebar>-->
  <!-- Main section-->
  <section class="section-container" >
    <!-- Page content-->
    <div class="c-layout-page">
      <router-outlet></router-outlet>
    </div>
  </section>
</div>
<app-loading></app-loading>
<app-notification></app-notification>
<app-cancel-alert></app-cancel-alert>
