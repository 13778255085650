import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';

/**
 * The AuthGuard is used to protect every page that only logged-in users should be able to access. This implementation
 * does this by checking if an AccessToken is present.
 *
 * To have a new component be protected by the AuthGuard, provide it as the 'canActivate' property of a Route object in
 * the AppRoutingModule.
 *
 * @author Sebastian Wenzel
 */
@Injectable({providedIn: 'root'})
export class AuthGuard extends KeycloakAuthGuard{
  constructor(
    protected readonly router: Router,
    protected readonly keycloak: KeycloakService,
  ) {
    super(router, keycloak);
  }

  public async isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    if (!this.authenticated) {
      await this.login();
    }
    return this.authenticated;
  }

  public async login() {
    await this.keycloak.login(
      {
        redirectUri: window.location.origin + "/systems",
      }
    ); 
  }

}
