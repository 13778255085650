import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {HeaderComponent} from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import {SharedModule} from '../modules/shared/shared.module';


/**
 * This module contains all layout-related components such as the header for the in-app navigation.
 *
 * @author Lukas Heiland
 */
@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent
  ],
  exports: [
    HeaderComponent,
    FooterComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
  ]
})
export class LayoutModule { }
